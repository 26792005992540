

































import LazyHydrate from 'vue-lazy-hydration';

import SfButton from '/storefrontUI/components/components/atoms/SfButton/SfButton.vue';
import SfCharacteristic from '/storefrontUI/components/components/molecules/SfCharacteristic/SfCharacteristic.vue';

import { ref, defineComponent } from '@nuxtjs/composition-api';
import StoresModal from '~/components/StoreSwitcher/StoresModal.vue';
import { useConfig } from '~/composables';

export default defineComponent({
  name: 'StoreSwitcher',
  components: {
    StoresModal,
    SfButton,
    SfCharacteristic,
    LazyHydrate,
  },
  setup() {
    const { config } = useConfig();

    const isLangModalOpen = ref(false);

    return {
      isLangModalOpen,
      storeConfig: config,
    };
  },
});
